import React from 'react'
import { Link, navigate } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import './index.less'
import Img from 'gatsby-image'

let projects_offset = 0
let temp_height = 0

const projects = [
  {
    title: 'Workplace One',
    color: '#478261',
    slug: 'workplace-one',
    image: require('../images/projects/project-main-workplace-one.jpg'),
  },
  {
    title: 'ERAS',
    color: '#000EE1',
    slug: 'eras',
    image: require('../images/projects/project-main-eras.jpg'),
  },
  {
    title: 'Everyday Icing',
    color: '#008EAA',
    slug: 'everyday-icing',
    image: require('../images/projects/project-main-edi.jpg'),
  },
]

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      projectsBG: 'black',
      scrollHeight: 0,
      y: 0,
      projects_offset: 0,
      title: 'hidden',
      project_max_height: 0,
    }
  }

  goTo = (e, link, white = false) => {
    e.preventDefault()
    if (white) {
      document.body.classList.add('cover', 'white')
    } else {
      document.body.classList.add('cover')
    }
    setTimeout(() => {
      navigate(link)
    }, 700)
  }

  componentDidMount = () => {
    document.body.classList.add('remove')
    const projects_offset = document.getElementById('featured_projects')
      ? document.getElementById('featured_projects').offsetTop -
        document.getElementById('featured_projects').scrollTop +
        document.getElementById('featured_projects').clientTop
      : 0
    this.setState({ projects_offset })
    document.body.classList.remove('stop-scroll')
    setTimeout(() => {
      document.body.classList.remove('cover', 'remove', 'white')
    }, 700)
    setTimeout(() => {
      this.setState({ title: 'visible' })
    }, 100)
    window.scroll(0, 0)
    document.addEventListener('scroll', this.handleScroll, false)
  }

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.handleScroll, false)
  }

  handleScroll = () => {
    this.setState({
      scrollHeight: window.scrollY,
    })
  }

  _onMouseMove(e, id) {
    // this.setState({ y: e.screenY });
    this.setState({ y: e.screenY })
    if (document.getElementById(id)) {
      this.setState({
        project_max_height:
          document.getElementById(id).offsetHeight -
          document.getElementById(id).firstChild.firstChild.offsetHeight,
      })
    }
  }

  changeBG = (e, projectsBG) => {
    if (typeof window !== 'undefined' && window.innerWidth >= 1200) {
      this.setState({ projectsBG })
    }
  }

  render() {
    const opacityControlHeight =
      typeof window !== 'undefined' ? window.innerHeight / 2 : 300
    let opacity = 1

    if (this.state.scrollHeight === 0) {
      opacity = 1
    } else if (this.state.scrollHeight < opacityControlHeight) {
      opacity =
        (opacityControlHeight - this.state.scrollHeight - 50) /
        opacityControlHeight
    } else {
      opacity = 0
    }

    // set offset for the projects
    if (
      typeof document !== 'undefined' &&
      typeof window !== 'undefined' &&
      document.getElementById('featured_projects')
    ) {
      temp_height =
        this.state.y -
        document.getElementById('featured_projects').getBoundingClientRect()
          .top -
        150
      if (temp_height > 0 && temp_height < this.state.project_max_height) {
        projects_offset = temp_height
      }
      if (temp_height > this.state.project_max_height) {
        projects_offset = this.state.project_max_height
      }
    }

    return (
      <Layout>
        <SEO
          title="Enso Digital | User Experience  and Web Development Studio"
          image={require('../images/og/home.jpg')}
          description="Enso Digital is a user experience and web development studio with a shared passion for building world-class digital products."
        />

        <div
          style={{ background: this.state.projectsBG }}
          className="bg-clr-fix"
        >
          <section
            className="home hero"
            data-uk-scrollspy="cls:uk-animation-fade"
          >
            <div
              className="uk-container uk-flex uk-flex-left uk-flex-middle"
              data-uk-height-viewport="expand: true"
            >
              <div
                style={{ opacity }}
                className={
                  'home-text-container uk-text-left ' + this.state.title
                }
              >
                <span>Our promise</span>
                <h1>
                  <span>To help you find digital</span>
                  <span>solutions for your business - today.</span>
                </h1>
              </div>
            </div>
          </section>

          <section className="home intro">
            <div className="uk-container">
              <div className="uk-grid">
                <div className="uk-width-1-1">
                  <div className="title">
                    <span>What we do</span>
                    <h2>
                      We concept, design, and deliver
                      <br />
                      best-in-class products and services.
                    </h2>
                  </div>
                </div>
                {[
                  'Create a new digital product',
                  'Improve an existing product',
                  'Launch a digital experiment',
                  'Strategy & product consulting',
                ].map((item, idx) => {
                  return (
                    <div
                      className="uk-width-1-1 uk-width-1-2@m uk-width-1-4@xl home-feature-box"
                      key={idx}
                    >
                      <img
                        src={require(`../images/who-we-are/icon-${
                          idx + 1
                        }.svg`)}
                        alt={item}
                      />
                      <span>0{idx + 1}</span>
                      <h3>{item}</h3>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>

          <section className="home projects">
            <div className="uk-container">
              <div className="uk-grid">
                <div className="uk-width-1-1">
                  <div className="title">
                    <span>Recent Projects</span>
                    <h2>
                      Explore a few of our projects that are
                      <br />
                      being used by thousands of people.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-container-expand">
              <ul className="featured-projects" id="featured_projects">
                {projects.map((project, idx) => {
                  return (
                    <li
                      className={`project-item ${idx === 2 ? 'last' : ''}`}
                      key={idx}
                    >
                      <Link
                        className="inner"
                        id={`project${idx + 1}`}
                        onClick={(e) =>
                          this.goTo(e, `/featured-projects/${project.slug}`)
                        }
                        to={`/featured-projects/${project.slug}`}
                        onMouseMove={(e) =>
                          this._onMouseMove(e, `project${idx + 1}`)
                        }
                        onMouseEnter={(e) => {
                          this.changeBG(e, project.color)
                        }}
                        onMouseLeave={(e) => {
                          this.changeBG(e, '#000000')
                        }}
                      >
                        <div className="content">
                          <h2
                            style={{
                              transform: `translateY(${projects_offset}px)`,
                            }}
                          >
                            {project.title}
                          </h2>
                          <div className="img-container">
                            <img
                              // fluid={
                              //   this.props.data[`projectImage${idx + 1}`]
                              //     .childImageSharp.fluid
                              // }
                              src={project.image}
                              alt={project.title}
                              style={{ position: 'absolute' }}
                            />
                          </div>
                        </div>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>

          <section className="home bottom-cta">
            <div>
              <div className="link">
                <Link
                  onClick={(e) => this.goTo(e, '/who-we-are', true)}
                  to="/who-we-are"
                >
                  Who we are
                </Link>
                <span>Studio</span>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
//
// export const query = graphql`
//   query {
//     projectImage1: file(
//       relativePath: { eq: "projects/project-main-workplace-one.jpg" }
//     ) {
//       childImageSharp {
//         fluid(maxWidth: 600) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     projectImage2: file(relativePath: { eq: "projects/project-main-edi.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 600) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     projectImage3: file(
//       relativePath: { eq: "projects/project-main-arcurve.jpg" }
//     ) {
//       childImageSharp {
//         fluid(maxWidth: 600) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `
